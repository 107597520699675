import React, {useEffect, useState, useCallback, useRef} from "react";
import PropTypes from "prop-types";
import {
    Grid,
    Paper,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import StyledLink from "../../common/StyledLink";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import {
    userFinalScoreResponse,
    viewResult,
    toggleResultButton,
    currentStatus,
    audioPlay,
    addAssessor,
    SaveAssessorNotes,
    getAssessorNotes,
    checkAllLevelAgentAudio,
    saveVideoStatus,
} from "../../../redux/sims/actions";
import AgentScript from "./AgentScript.jsx";
import OtherScript from "./OtherScript.jsx";
import { useLocation } from "react-router-dom";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import CloseIcon from "@material-ui/icons/Close";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DashboardPermissions from "../../../permissions/dashboard";
import { permissionCheck } from "../../../utils/permissionCheck";
import {
    audioMerge,
    fetchVideoDetails,
    saveScreenShareVideo,
} from "../../../services/result.service";
import { useStyles } from "./TakeSimResult";
import ResultScreenAccuracies from './ResultScreenAccuracies.jsx';
import TypingAndSelection from './TypingAndSelection.jsx';
import moment from "moment/moment";
import SplitButton from "../../common/SplitButton.jsx";
import SimsPermissions from '../../../permissions/sims';
import SaveIcon from "@material-ui/icons/Save";
import StarsIcon from "@material-ui/icons/Stars";
import Typography from "@material-ui/core/Typography";
import VideoLoadingPoster from '../../../assets/images/video_loading.gif';

const TakeSimResult = ({
    elapsedTime,
    wrapSeconds,
    userFinalScoreResponse,
    toggleResultButton,
    viewResult,
    userResponse,
    userResult,
    setUserResult,
    caseValuesArray,
    user,
    isPost,
    simId,
    currentAudioPlaying,
    currentStatus,
    audioPlaying,
    audioPlay,
    assessorNotes,
    addAssessor,
    SaveAssessorNotes,
    getAssessorNotes,
    assessor_notes,
    checkAllLevelAgentAudio,
    videoRecordingEnabled,
    videoUploadStatus,
    saveVideoStatus,
    simProductType,
    config,
}) => {
    const classes = useStyles();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const assignment_id = urlParams.get("assignment_id");
    const [notes, setNotes] = React.useState("");
    const agent_user_id = urlParams.get("user_id");
    const sim_id = urlParams.get("sim_id");
    const reviewer_come = urlParams.get("reviewer");
    const [noteStatus, setNoteStatus] = React.useState("view");
    const [open, setOpen] = React.useState(false);
    const [video, setVideo] = React.useState("");
    const [videoTitle, setVideoTitle] = React.useState('');
    const [videoDetailsFetched, setVideoDetailsFetched] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [callTranscript, setCallTranscript] = React.useState(0);
    const [enableVideoBtn, setEnableVideoBtn] = React.useState(false);
    const [disablePolling, setDisablePolling] = React.useState(false);
    const [progress, setProgress] = useState(0);
    const [sendingToSnowfly, setSendingToSnowfly] = React.useState(false);
    const [sentToSnowfly, setSentToSnowfly] = React.useState(false);
    const [snowflyId, setSnowflyId] = useState(userResult?.snowfly_id ?? null);
    const [currentAudio, setCurrentAudio] = React.useState({});
    const [timePlay, setTimePlay] = React.useState(0);
    const [callOrAgentPlay, setCallOrAgentPlay] = React.useState(audioPlaying);
    const [lastFive, setLastFive] = React.useState("");
    const [hideSaveVideoButton, setHideSaveVideoButton] = React.useState(false);
    const [hideBestPracticeVideoButton, setHideBestPracticeVideoButton] = React.useState(false);
    const [saveMessage, setSaveMessage] = React.useState('');
    const [saveMessageShow, setSaveMessageShow] = React.useState(false);
    const videoPollTimer = useRef(null);

    const videoPollTime = 30000;
    let simLevelId = urlParams.get("levelId");

    useEffect(() => {
        if (userResult && userResult.scripts && userResult.scripts.length) {
            setUserResult(userResult);
            setSnowflyId(userResult?.snowfly_id ?? null);
        }
    }, [userResult, setUserResult, setSnowflyId]);

    useEffect( () => {
        if (userResult && userResult.scripts && userResult.scripts.length) {
            setUserResult(userResult);
        }
    }, [userResult, setUserResult, sentToSnowfly]);

    useEffect(() => {
        if (
            userResult && (
                (userResult.screen_share_video && (userResult.screen_share_video.status === 'saved')) ||
                (userResult.video_url && (userResult.video_status === 'saved'))
            )
        ) {
            setVideoDetailsFetched(true);
            setEnableVideoBtn(true);
            setVideo(userResult?.video_url ?? null);
            setProgress(100);
        }
    }, [userResult]);

    useEffect(() => {
        if (!isPost) {
            const payload = {
                simLevelId,
                simId: sim_id ? sim_id : simId,
                ...(assignment_id ? { assignment_id } : {}),
            };
            viewResult(payload, agent_user_id);
        } else {
            let lapsedTime = elapsedTime;
            let timeInSeconds = elapsedTime;
            if (userResponse.resumeLevel && elapsedTime && userResponse.elapsedTime) {
                let floatVal;
                if (!isNaN(lapsedTime) && !isNaN(userResponse.elapsedTime)) {
                    floatVal = lapsedTime + userResponse.elapsedTime;
                } else {
                    floatVal = (parseFloat(((''+lapsedTime)
                            .replace(/\s/g, ''))
                            .replace('m', ".")
                        ) + parseFloat(((''+userResponse.elapsedTime).replace(/\s/g, ''))
                            .replace('m', "."))
                    );
                }
                const totalSeconds = parseInt(floatVal) * 60 + parseInt((floatVal % 1).toFixed(2).substring(2))
                timeInSeconds = totalSeconds;
                lapsedTime = totalSeconds;
            }
            const payload = {
                ...caseValuesArray,
                isPost: true,
                assignment_id: userResponse.assignment_id,
                elapsedTime: lapsedTime,
                totalSeconds: timeInSeconds,
                wrapSeconds: wrapSeconds,
            };
            if (elapsedTime) {
                userFinalScoreResponse(userResponse._id, payload);
            }

        }
        return () => {
            currentStatus(0);
            audioPlay("");
        };
    }, [
        userResponse,
        userFinalScoreResponse,
        caseValuesArray,
        viewResult,
        isPost,
        simId,
        simLevelId,
        assignment_id,
        sim_id,
        agent_user_id,
        elapsedTime,
        currentStatus,
        audioPlay,
        wrapSeconds
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        toggleResultButton(true);
        return () => toggleResultButton(false);
    });

    useEffect(() => {
        if (!isPost) {
            if (
                !permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type
                ) &&
                !permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type
                ) &&
                permissionCheck(
                    DashboardPermissions.assessor_notes.view.task,
                    DashboardPermissions.assessor_notes.view.type
                )
            ) {
                if (userResult._id) {
                    getAssessorNotes(userResult._id);
                }
                setNotes(assessor_notes);
                setNoteStatus("view");
            } else if (
                permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type
                ) ||
                permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type
                )
            ) {
                if (userResult._id) {
                    getAssessorNotes(userResult._id);
                }
                setNotes(assessor_notes);
                setNoteStatus(assessor_notes === "" ? "add" : "edit");
            }
        } else {
            if (
                !permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type
                ) &&
                !permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type
                ) &&
                permissionCheck(
                    DashboardPermissions.assessor_notes.view.task,
                    DashboardPermissions.assessor_notes.view.type
                )
            ) {
                setNoteStatus("view");
            } else if (
                permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type
                ) ||
                permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type
                )
            ) {
                setNoteStatus("add");
            }
        }
    }, [getAssessorNotes, userResult._id, assessor_notes, isPost]);

    useEffect(() => {
        const simID = sim_id ? sim_id : simId;
        checkAllLevelAgentAudio(simID, assignment_id, agent_user_id);
    }, [sim_id, simId, checkAllLevelAgentAudio, agent_user_id, assignment_id]);

    useEffect( () => {
        let curVideo = userResult?.screen_recording_urls?.find((item) => {
            return item.url === video;
        }) ?? null;
        if (!curVideo) {
            curVideo = userResult.screen_recording_urls ? userResult.screen_recording_urls[0] : null;
        }

        if (curVideo && (curVideo.url === video)) {
            let saved_date = curVideo.saved_on ? moment(curVideo.saved_on ) : null;
            setHideSaveVideoButton(saved_date !== null);

            let best_practice_date = curVideo.marked_best_practice_on ? moment(curVideo.marked_best_practice_on ) : null;
            setHideBestPracticeVideoButton(best_practice_date !== null);
        }
    }, [video, userResult, userResult.screen_recording_urls]);

    const handleClose = () => {
        addAssessor(false);
        setNotes(assessor_notes);
    };

    const handleClosePreview = () => {
        setOpen(false);
        setHideBestPracticeVideoButton(false);
        setHideSaveVideoButton(false);
        setVideo(null);
    };

    /**
     * Marks the currently selected screen share recording as saved/best-practice (Marking as best practice implies save)
     * TODO - Check permissions
     *
     * @param ev
     */
    const handleSaveScreenShareVideo = async (ev) => {
        const isBestPractice = ev.currentTarget.id === 'bestPracticeScreenRecording';
        let result = await saveScreenShareVideo(
            userResult.user?._id ?? user._id,
            video,
            isBestPractice
        );

        let screenShareVideos = userResult.screen_recording_urls ? [...userResult.screen_recording_urls] : [];
        let item = screenShareVideos.find(elem => elem.url === video);
        if (item) {
            item.saved_on = result.video_details.saved_on;
            item.marked_best_practice_on = result.video_details.marked_best_practice_on;
            setVideoTitle(item.text);
        }

        setUserResult({...userResult, screen_recording_urls: []});
        setVideo(null);
        setSaveMessage(
            isBestPractice ? 'Video was marked as Best Practice': 'Video was Saved'
        );
        setSaveMessageShow(true);
        setUserResult({...userResult, screen_recording_urls: screenShareVideos});
        setVideo(video);
        setTimeout(() => setSaveMessageShow(false), 2500);
    }

    const openPreview = () => {
        if (
            //!isPost &&
            !video &&
            (userResult.video_status === "saved" && userResult.video_url)
        ) {
            setVideo(userResult.video_url);
        } else if (
            !video &&
            (userResult && userResult.screen_recording_urls && userResult.screen_recording_urls.length)
        ) {
            setVideo(userResult.screen_recording_urls[0].url);
            setVideoTitle(moment(userResult.screen_recording_urls[0].created_on).format('MM-DD-YYYY HH:MM:ss A'));
        }
        setOpen(true);
    };

    const handleMenuItemClick = (index, option) => {
        setVideo(null);
        setVideo(option.url);
        setVideoTitle(option.text);
        setOpen(true);
    }

    const handleAdd = () => {
        const payload = {
            assessor_notes: notes,
        };
        SaveAssessorNotes(userResult._id, payload);
        addAssessor(false);
    };

    const handleEdit = () => {
        const payload = {
            assessor_notes: notes,
        };
        SaveAssessorNotes(userResult._id, payload, true);
        addAssessor(false);
    };

    const getVideoDetails = useCallback(async () => {
        videoPollTimer.current && clearInterval(videoPollTimer.current);
        videoPollTimer.current = null;
        try {
            setOpenAlert(false);

            const videoDetails = await fetchVideoDetails(userResponse._id);
            if (!videoDetails || !videoDetails.data) {
                throw new Error('Could not fetch video details');
            }

            if ((videoDetails.data.video_status === "saved") && (progress !== 100)) {
                setUserResult({
                    ...userResult,
                    video_status: videoDetails.data.status,
                    video_url: videoDetails.data.video_url,
                    screen_share_video: videoDetails.data.video_info,
                });
                setVideoDetailsFetched(true);
                saveVideoStatus(true);
                setEnableVideoBtn(true);
                setVideo(videoDetails.data.video_url);
                setProgress(100);
            } else {
                if (videoDetails.data.video_status === "failed") {
                    throw new Error('Video processing failed');
                }
                videoPollTimer.current = setInterval(getVideoDetails, videoPollTime);
            }
        } catch (err) {
            setOpenAlert(true);
        }
    }, [userResponse._id, userResult, setUserResult, saveVideoStatus, progress]);

    const showVideoDownload = () => {
        const canDownloadVideos = permissionCheck(
            SimsPermissions.download_video.view.task,
            SimsPermissions.download_video.view.type
        );
        if (canDownloadVideos && (videoDetailsFetched || video /*userResponse.video_url || userResult.video_url*/)) {
            return (
                <Tooltip
                    title={"Download Screen Share Video"}
                    placement="bottom"
                >
                    <span className={classes.editSpan}>
                        <Button
                            variant="contained"
                            className={classes.preview}
                            size="small"
                            href={video /*userResponse.video_url ? userResponse.video_url : userResult.video_url*/}
                            target={"_blank"}
                            startIcon={(<VideoLibraryIcon/>)}
                        >Download Screen Recording</Button>
                    </span>
                </Tooltip>
            );
        }

        return null;
    };

    const showVideoPreview = () => {
        if (isPost && videoRecordingEnabled) {
            if (!videoDetailsFetched) {
                videoPollTimer.current && clearInterval(videoPollTimer.current);
                videoPollTimer.current = setInterval(getVideoDetails, videoPollTime);
            }
            return (
                <Tooltip
                    title={
                        videoDetailsFetched && videoUploadStatus !== null
                            ? ""
                            : (<><Typography color="inherit">Video was successfully uploaded and is now being processed.</Typography><Typography color="inherit">This may take some time, so feel free to come back later.</Typography></>)
                    }
                    style={{fontSize: '1rem'}}
                    placement="bottom"
                >
                    <span>
                        <Button
                            variant="contained"
                            className={classes.preview}
                            size="small"
                            onClick={openPreview}
                            disabled={!videoDetailsFetched && !enableVideoBtn}
                            startIcon={videoDetailsFetched && videoUploadStatus !== null ? (
                                <VideoLabelIcon />
                            ) : (
                                <CircularProgress className={classes.previewLoader} />
                            )}
                        >
                            Preview Recording
                        </Button>
                    </span>
                </Tooltip>
            );
        } else if (
            !isPost &&
            ((userResult.video_status === "saved" && userResult.video_url) || userResult.screen_recording_urls?.length)
        ) {
            if ((user.role).toLowerCase() === 'agent') {
                return (
                    <Button
                        variant="contained"
                        className={classes.preview}
                        size="small"
                        onClick={openPreview}
                        startIcon={<VideoLabelIcon />}
                        style={{maxWidth: '32ch'}}
                    >
                        Preview Screen Recording
                    </Button>
                )
            }

            return (
                <SplitButton
                    options={userResult.screen_recording_urls.map((item) => {
                        return {
                            url: item.url,
                            disabled: false,
                            selected: item.url === video,
                            text: moment(item.created_on).format('MM-DD-YYYY HH:mm:ss A'),
                            data: item,
                            callback: buildVideoMenuItem
                        }
                    })}
                    onItemSelected={handleMenuItemClick}
                    mainButtonIcon={<VideoLabelIcon />}
                    onMainButtonClick={openPreview}
                    mainButtonText={'Preview Screen Recording'}
                ></SplitButton>
            );
        }

        return null;
    };

    const showVideoSaveButtons = () => {
        const canDownloadVideos = permissionCheck(
            SimsPermissions.download_video.view.task,
            SimsPermissions.download_video.view.type
        );
        if (canDownloadVideos && (videoDetailsFetched || video)) {
            return (
                <>
                    <Tooltip
                        title={hideSaveVideoButton ? "The Video has been saved already" : "Save Screen Share Video"}
                        placement="bottom"
                    >
                        <span className={classes.editSpan}>
                            <Button
                                id={'saveScreenRecording'}
                                variant="contained"
                                className={classes.preview}
                                size="small"
                                onClick={handleSaveScreenShareVideo}
                                startIcon={(<SaveIcon/>)}
                                disabled={hideSaveVideoButton}
                            >Save Screen Recording</Button>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={hideBestPracticeVideoButton ? "The recording has already been marked as Best Practice" : "Marks the Screen Sharing Video as Best Practice"}
                        placement="bottom"
                    >
                        <span className={classes.editSpan}>
                            <Button
                                id={'bestPracticeScreenRecording'}
                                variant="contained"
                                className={classes.preview}
                                size="small"
                                onClick={handleSaveScreenShareVideo}
                                startIcon={(<StarsIcon/>)}
                                disabled={hideBestPracticeVideoButton}
                            >Mark as Best Practice</Button>
                        </span>
                    </Tooltip>
                </>
            )
        } else {
            return null;
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlert(false);
    };

    const setTimePlayfun = (audioPlayer, data, type) => {
        setCallOrAgentPlay(audioPlaying ? audioPlaying : '');
        if (type === 'agent' && data.agentId) {
            userResult.agent_scripts.map((script) => {
                script.play = (script.agentId !== data.agentId);
                return true;
            });
            userResult.scripts.map((script) => {
                script.play = true;
                return true;
            });
        } else {
            userResult.scripts.map((script) => {
                script.play = (script._id === data._id);
                return userResult;
            });
            userResult.agent_scripts.map((script) => {
                script.play = true;
                return true;
            });
        }
        setUserResult(userResult)
        setCurrentAudio(audioPlayer);
        setTimePlay((prev) => prev + 1)
    };

    const pauseFunction = (data, type) => {
        if (type === 'agent' && data.agentId) {
            userResult.agent_scripts.map((script) => {
                if (script.agentId === data.agentId) {
                    script.play = true;
                    setTimePlay(0)
                }
                return userResult;
            });
        } else {
            userResult.scripts.map((script) => {
                if (script._id === data._id) {
                    script.play = true;
                    setTimePlay(0)
                }
                return userResult;
            });
        }
        setUserResult(userResult);
    };

    const sendAudioToSnowfly = async () => {
        const canSendToSnowfly = config.snowFlyAudioMerge &&
            (
                (userResult.level && userResult.level.send_to_snowfly) ||
                (userResult.level && userResult.level.send_to_snowfly) ||
                (userResult.send_to_snowfly)
            );

        if(canSendToSnowfly) {
            setSendingToSnowfly(true);
            const sendData = {
                simName: userResult?.simTitle ?? userResult.simTitle,
                levelName: userResult?.level?.name ?? userResult?.level.name,
                levelNum: (userResult?.level?.order ?? userResult?.level?.order ?? 0)+1,
                simId: simId,
                responseId: userResult?._id ?? userResult?._id,
                simLevelId: simLevelId,
                user: user,
            }

            try {
                let result = await audioMerge(sendData);
                if (result && result.data && result.data.success) {
                    let userRes = {...userResult};
                    userRes.snowfly_id = result.data.snowflyId;
                    setUserResult(userRes);
                    setSnowflyId(userRes.snowfly_id);
                    setSentToSnowfly(true);
                }
            } catch (err) {
                setSentToSnowfly(false);
                if (window.IS_DEVELOPMENT) {
                    console.error('Failed Sending recording of this level to Snowfly = %o, %o', sendData, err);
                }
            }
            setSendingToSnowfly(false);
        }
    };

    const buildVideoMenuItem = (option, data) => {
        return (
            <>
                {data.marked_best_practice_on ?
                    (<StarsIcon/>) :
                    data.saved_on ? (<SaveIcon/>) : (<span style={{marginRight: '2em'}}></span>)
                }
                {option.text}
            </>
        )
    }

    if (isPost && videoRecordingEnabled && !disablePolling) {
        if (videoUploadStatus) {
            videoPollTimer.current && clearInterval(videoPollTimer.current);
            videoPollTimer.current = setInterval(getVideoDetails, videoPollTime);
        }

        setDisablePolling(true);
    }

    return (
        <>
            {openAlert ? (
                <Alert
                    variant="filled"
                    className={classes.alertBox}
                    onClose={handleAlertClose}
                    severity="error"
                >
                    Error in saving the screen recording!
                </Alert>
            ) : null}
            <Grid container style={{ marginBottom: "10px", flexDirection: "row", alignContent: "space-between", flexShrink: "1" }} className={classes.flex}>
                <Grid item xs={5}>
                    {!sim_id && (
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            className={classes.mainBreadBlock}
                        >
                            <StyledLink to="/dashboard" className={classes.breadcrumbs}>
                                Dashboard
                            </StyledLink>
                        </Breadcrumbs>
                    )}
                </Grid>

                <Grid item xs={2}>

                </Grid>

                <Grid item xs={5} style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '1em',
                    flex: '1',
                    flexWrap: 'wrap',
                    margin: '0 auto'
                }}>
                    {reviewer_come === "false" ? (
                        <StyledLink
                            color="inherit"
                            underline="always"
                            to={{
                                pathname: "/sims",
                                state:
                                    location.state.assignment && location.state.assignment.sim_id
                                        ? {
                                            ...location.state.assignment,
                                            reviewer: false,
                                            type: location.state.type,
                                        }
                                        : {
                                            sim_id: sim_id || simId,
                                            reviewer: false,
                                            type: location.state.type,
                                        },
                            }}
                        >
                            <Tooltip
                                title='Re-Take or go to next level'
                                placement="bottom"
                            >
                                <span>
                                    <Button
                                        variant="contained"
                                        className={classes.retake}
                                        disableElevation
                                        color="primary"
                                        size="small"
                                    >
                                        Re-take
                                    </Button>
                                </span>
                            </Tooltip>
                        </StyledLink>
                    ) : (
                        <StyledLink
                            color="inherit"
                            underline="always"
                            to={{
                                pathname: "/sims",
                                state:
                                    location.state.assignment && location.state.assignment.sim_id
                                        ? { ...location.state.assignment, reviewer: true }
                                        : {
                                            sim_id: sim_id || simId,
                                            reviewer: true,
                                            assignment_id,
                                            agent_user_id,
                                        },
                            }}
                        >
                            <Button
                                variant="contained"
                                className={classes.retake}
                                disableElevation
                                color="primary"
                                size="small"
                            >
                                View Other Level Result
                            </Button>
                        </StyledLink>
                    )
                    }

                    {showVideoPreview()}
                </Grid>
            </Grid>
            <Dialog onClose={handleClosePreview} open={open} fullWidth maxWidth="md">
                <DialogTitle className={classes.previewDialogTitle}>
                    Screen Recording {videoTitle}
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClosePreview}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.previewDialog}>
                        <video
                            className={classes.videoModal}
                            poster={VideoLoadingPoster}
                            width="750"
                            height="500"
                            controls
                            autoPlay
                        >
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                    <div style={{padding: '0em',
                        textAlign: 'left',
                        height: '4em',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignContent: 'center',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '1rem'}}
                    >
                        {showVideoDownload()}
                        {showVideoSaveButtons()}
                        {saveMessageShow && (<p className={classes.success}>{saveMessage}</p>)}
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                onClose={handleClose}
                open={assessorNotes}
                classes={{ paper: classes.dialog }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Assessor Notes</DialogTitle>
                <DialogContent>
                    <div style={{ padding: "0.5rem", width: "27vw" }}>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                style: { fontSize: 14, paddingLeft: 8 },
                            }}
                            fullWidth
                            multiline
                            minRows={5}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            className={classes.textArea}
                            placeholder="Type your note here"
                            disabled={
                                !permissionCheck(
                                    DashboardPermissions.assessor_notes.update.task,
                                    DashboardPermissions.assessor_notes.update.type
                                ) &&
                                !permissionCheck(
                                    DashboardPermissions.assessor_notes.create.task,
                                    DashboardPermissions.assessor_notes.create.type
                                ) &&
                                permissionCheck(
                                    DashboardPermissions.assessor_notes.view.task,
                                    DashboardPermissions.assessor_notes.view.type
                                )
                            }
                        />
                        <Button
                            size="small"
                            style={{
                                fontWeight: 600,
                                padding: "3px 11px",
                                backgroundColor: "#F8DEE7",
                                float: "right",
                                color: "#A55B73",
                                margin: "8px 0px",
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        {noteStatus === "edit" ? (
                            <Tooltip
                                title={
                                    !permissionCheck(
                                        DashboardPermissions.assessor_notes.update.task,
                                        DashboardPermissions.assessor_notes.update.type
                                    )
                                        ? "You don't have permission to update"
                                        : ""
                                }
                                placement="top"
                            >
                                <span className={classes.editSpan}>
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        disabled={
                                            !permissionCheck(
                                            DashboardPermissions.assessor_notes.update.task,
                                            DashboardPermissions.assessor_notes.update.type
                                            )
                                        }
                                        onClick={handleEdit}
                                    >
                                        Save
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : noteStatus === "add" ? (
                            <Tooltip
                                title={
                                    !permissionCheck(
                                        DashboardPermissions.assessor_notes.create.task,
                                        DashboardPermissions.assessor_notes.create.type
                                    )
                                        ? "You don't have permission to create"
                                        : ""
                                }
                                placement="top"
                            >
                                <span className={classes.editSpan}>
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        disabled={
                                            !permissionCheck(
                                            DashboardPermissions.assessor_notes.create.task,
                                            DashboardPermissions.assessor_notes.create.type
                                            )
                                            }
                                        onClick={handleAdd}
                                    >
                                        Save
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : null}
                    </div>
                </DialogContent>
            </Dialog>
            {Object.keys(userResult).length > 0 ?
                (<Grid container spacing={4}>
                    <ResultScreenAccuracies
                        userResult={userResult}
                        setUserResult={setUserResult}
                        snowflyId={snowflyId}
                        setSnowflyId={setSnowflyId}
                        isPost={isPost}
                        config={config}
                        sendAudioToSnowfly={sendAudioToSnowfly}
                        sendingToSnowfly={sendingToSnowfly}
                    />
                    <Grid container className={classes.girdContainer} item md={simProductType.includes('Voice Only') ? 9 : 5}>
                        <Paper className={`${classes.root}`}>
                            <h2>Call Transcript</h2>
                            {userResult?.scripts &&
                                userResult?.scripts.map((singleScript, index) => {
                                    if (callOrAgentPlay && !audioPlaying) {
                                        singleScript.play = undefined;
                                        setCallOrAgentPlay(audioPlaying);
                                        setTimePlay(0);
                                    }
                                    if (singleScript.play === undefined) {
                                        singleScript.play = true;
                                    }
                                    if (singleScript.sim_character_id.type === "agent") {
                                        const currentAgentScript = userResult.agent_scripts.filter(
                                            (ele) =>
                                                ele.sim_script_id.toString() ===
                                                singleScript._id.toString()
                                        );
                                        // eslint-disable-next-line
                                        currentAgentScript.map((agent, idx) => {
                                            agent.id = idx;
                                            if (agent.play === undefined) {
                                                agent.play = true;
                                            } else if (callOrAgentPlay && !audioPlaying) {
                                                agent.play = true;
                                                setCallOrAgentPlay(audioPlaying);
                                                setTimePlay(0);
                                            }
                                        });
                                        return (
                                            <Paper className={classes.agentList} key={index}>
                                                <AgentScript
                                                    key={index}
                                                    index={index}
                                                    simCharacter={singleScript.sim_character_id.type}
                                                    currentAudioPlaying={currentAudioPlaying}
                                                    totalLength={userResult.scripts.length - 1}
                                                    // status={status}
                                                    setLastFive={setLastFive}
                                                    currentStatus={currentStatus}
                                                    audioPlaying={audioPlaying}
                                                    singleScript={singleScript}
                                                    currentAgentScript={currentAgentScript}
                                                    latestAgentScript={currentAgentScript[0]}
                                                    callTranscript={callTranscript}
                                                    setCallTranscript={setCallTranscript}
                                                    setTimePlayfun={setTimePlayfun}
                                                    currentAudio={currentAudio}
                                                    timePlay={timePlay}
                                                    pauseFunction={pauseFunction}
                                                />
                                                <div key={singleScript._id}>
                                                    <Accordion className={classes.accordian}>
                                                        <AccordionSummary
                                                            expandIcon={
                                                                <ExpandMoreIcon className={classes.expand} />
                                                            }
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <p className={classes.heading}>
                                                                Last 5 agent audios
                                                            </p>
                                                        </AccordionSummary>
                                                        {currentAgentScript.length > 0 &&
                                                            currentAgentScript
                                                                .slice(1)
                                                                .map((latestAgentScript, i) => {
                                                                    if (
                                                                        lastFive &&
                                                                        lastFive.audio_url &&
                                                                        lastFive.audio_url ===
                                                                        latestAgentScript.audio_url
                                                                    ) {
                                                                        return (
                                                                            <AccordionDetails key={i}>
                                                                                <AgentScript
                                                                                    key={i+1}
                                                                                    index={i+1}
                                                                                    simCharacter={
                                                                                        latestAgentScript.sim_character_id.type
                                                                                    }
                                                                                    currentAudioPlaying={
                                                                                        currentAudioPlaying
                                                                                    }
                                                                                    totalLength={
                                                                                        userResult.scripts.length - 1
                                                                                    }
                                                                                    // status={status}
                                                                                    currentStatus={currentStatus}
                                                                                    audioPlaying={audioPlaying}
                                                                                    singleScript={singleScript}
                                                                                    value={true}
                                                                                    currentAgentScript={currentAgentScript}
                                                                                    latestAgentScript={latestAgentScript}
                                                                                    setLastFive={setLastFive}
                                                                                    setTimePlayfun={setTimePlayfun}
                                                                                    currentAudio={currentAudio}
                                                                                    timePlay={timePlay}
                                                                                    pauseFunction={pauseFunction}
                                                                                />
                                                                            </AccordionDetails>
                                                                        );
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <AccordionDetails key={i}>
                                                                                <AgentScript
                                                                                    key={i+1}
                                                                                    index={i+1}
                                                                                    currentAudioPlaying={
                                                                                        currentAudioPlaying
                                                                                    }
                                                                                    totalLength={
                                                                                        userResult.scripts.length - 1
                                                                                    }
                                                                                    // status={status}
                                                                                    currentStatus={currentStatus}
                                                                                    audioPlaying={audioPlaying}
                                                                                    singleScript={singleScript}
                                                                                    currentAgentScript={currentAgentScript}
                                                                                    latestAgentScript={latestAgentScript}
                                                                                    onLoadedData={
                                                                                        lastFive.audio_url ===
                                                                                        latestAgentScript.audio_url
                                                                                    }
                                                                                    setTimePlayfun={setTimePlayfun}
                                                                                    currentAudio={currentAudio}
                                                                                    timePlay={timePlay}
                                                                                    pauseFunction={pauseFunction}
                                                                                />
                                                                            </AccordionDetails>
                                                                        );
                                                                    }
                                                                })}
                                                    </Accordion>
                                                </div>
                                            </Paper>
                                        );
                                    } else {

                                        return (
                                            <OtherScript
                                                index={index}
                                                currentAudioPlaying={currentAudioPlaying}
                                                totalLength={userResult?.scripts.length - 1 ?? 0}
                                                audioPlaying={audioPlaying}
                                                key={singleScript._id}
                                                singleScript={singleScript}
                                                callTranscript={callTranscript}
                                                setCallTranscript={setCallTranscript}
                                                setTimePlayfun={setTimePlayfun}
                                                currentAudio={currentAudio}
                                                timePlay={timePlay}
                                                pauseFunction={pauseFunction}
                                            />
                                        );
                                    }
                                })}
                        </Paper>
                    </Grid>
                    {!simProductType.includes('Voice Only') && <TypingAndSelection />}
                </Grid>) :
                <div style={{ textAlign: 'center', padding: '55px' }}>
                    <CircularProgress />
                </div>}
        </>
    );
};

TakeSimResult.propTypes = {
    elapsedTime: PropTypes.number,//string,
    totalSeconds: PropTypes.number,
    wrapSeconds: PropTypes.number,
    userFinalScoreResponse: PropTypes.func,
    toggleResultButton: PropTypes.func,
    viewResult: PropTypes.func,
    userResponse: PropTypes.object,
    userResult: PropTypes.object,
    user: PropTypes.object,
    caseValuesArray: PropTypes.object,
    isPost: PropTypes.bool,
    currentAudioPlaying: PropTypes.any,
    currentStatus: PropTypes.func,
    audioPlaying: PropTypes.string,
    audioPlay: PropTypes.func,
    assessorNotes: PropTypes.bool,
    addAssessor: PropTypes.func,
    SaveAssessorNotes: PropTypes.func,
    getAssessorNotes: PropTypes.func,
    assessor_notes: PropTypes.string,
    checkAllLevelAgentAudio: PropTypes.func,
    allAudioProcessed: PropTypes.bool,
    videoRecordingEnabled: PropTypes.bool,
    videoUploadStatus: PropTypes.bool,
    videoBlob: PropTypes.object,
    saveVideoStatus: PropTypes.func,
    simProductType: PropTypes.string
};

const mapStateToProps = (state) => ({
    elapsedTime: state.sim.elapsedTime,
    totalSeconds: state.sim.totalSeconds,
    wrapSeconds: state.sim.wrapSeconds,
    userResponse: state.sim.userResponse,
    userResult: state.sim.userResult,
    user: state.login.user,
    caseValuesArray: state.sim.caseValuesArray,
    resultScreenInputData: state.sim.resultScreenInputData,
    simId: state?.sim?.stats?.result?.sim_id,
    assessorNotes: state.sim.assessorNotes,
    currentAudioPlaying: state.sim.currentAudioPlaying,
    audioPlaying: state.sim.audioPlaying,
    assessor_notes: state.sim.assessor_notes,
    allAudioProcessed: state.sim.allAudioProcessed,
    videoRecordingEnabled: state.sim.videoRecordingEnabled,
    videoUploadStatus: state.sim.videoUploadStatus,
    videoBlob: state.sim.videoBlob,
    simProductType: state.sim.currentSimProductType,
});

const mapDispatchToProps = {
    userFinalScoreResponse: userFinalScoreResponse,
    viewResult,
    toggleResultButton,
    currentStatus,
    audioPlay,
    addAssessor,
    SaveAssessorNotes,
    getAssessorNotes,
    checkAllLevelAgentAudio,
    saveVideoStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(TakeSimResult);
