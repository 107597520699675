import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  InputLabel,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { connect } from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  loginUser,
  fetchPermissionDetails,
  updateLoginStatus,
  registerUser,
} from '../../redux/authentication/action';
import {forgotPassword} from '../../services/result.service';
import loginBg from '../../assets/images/login/Login_Image_noBG.png';
import iLearnLogo from '../../assets/images/icons/iLearnLogo.png';
import LoginStyle from './Login';
import crypto from "crypto";

const Login = ({
    loginUser,
    isAuth,
    fetchPermissionDetails,
    errMessage,
    loginStatus,
    updateLoginStatus,
    registrationStatus,
    registrationMessage,
    registerUser,
}) => {
    const useStyles = makeStyles((theme) => LoginStyle(theme));
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [openRegistration, setOpenRegistration] = useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertSeverity, setSeverity] = React.useState('');
    const [alertMesage, setAlertMessage] = React.useState('');
    const [flag, setFlag] = React.useState(false);
    const [registrationForm, setRegistrationForm] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        company_name: '',
        showPassword: false,
    });
    const [loading, setLoading] = useState(false);
    const [registerFlag, setRegisterFlag] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState(true);
    const [openRegistrationAlert, setOpenRegistrationAlert] = React.useState(false);
    const [alertRegistrationMesage, setAlertRegistrationMessage] = React.useState('');
    const [alertRegistrationSeverity, setAlertRegistrationSeverity] = React.useState('');

    const rlcKey = 'XAB1QxEF7Wu/NRAG4';
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const allowNewCompanyParam = urlParams.get("allowNewCompany");
    const crypted = (allowNewCompanyParam && allowNewCompanyParam.length && rlcKey && rlcKey.length) ?
            crypto.createHmac('sha1', rlcKey)
                .update(Buffer.from(allowNewCompanyParam))
                .digest('base64') :
            '';
    const allowNewCompany = crypted === 'VFaBnk6/M8WwPi+BKe1DqebLthY=';

    // const handleCheckBox = () => {
    //   checked ? setChecked(false) : setChecked(true);
    // };

  const submitForm = (e) => {
    setFlag(false);
    setRegisterSuccess(true);
    setRegisterFlag(false);
    e.preventDefault();
    if (email.trim() !== '' && password.trim() !== '') {
      const pattern = new RegExp(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, //eslint-disable-line
      );
      if (!pattern.test(email)) {
        alert('Please enter valid email address!!');
      } else {
        const payload = {
          email,
          password,
        };
        loginUser(payload);
      }
    } else {
      alert('Please enter your email and password');
    }
  };
  if (isAuth) {
    fetchPermissionDetails();
    return (
        <Redirect to={localStorage.getItem('redirectUrl') || '/dashboard'} />
    );
  }

  if (!isAuth && loginStatus === false && !flag) {
    setFlag(true);
    updateLoginStatus(null);
    setAlertMessage(errMessage);
    setOpenAlert(true);
    setSeverity('error');
  }

  const handleClose = () => {
    setOpenRegistration(false);
    setRegistrationForm({
      ...registrationForm,
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      password: '',
      company_name: '',
      showPassword: false,
    });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    setOpenRegistrationAlert(false);
  };

  const createAccount = (e) => {
    setRegisterFlag(false);
    setRegisterSuccess(true);
    e.preventDefault();
    if (registrationForm.email.trim() !== '' && registrationForm.password.trim() !== '') {
      const pattern = new RegExp(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, //eslint-disable-line
      );
      if (!pattern.test(registrationForm.email)) {
        alert('Please enter valid email address!!');
      } else {
        setLoading(true);
        const payload = {
          email: registrationForm.email,
          password: registrationForm.password,
          first_name: registrationForm.first_name,
          last_name: registrationForm.last_name,
          company_name: registrationForm.company_name,
          phone_number: registrationForm.phone_number,
        };
        registerUser(payload);
        setOpenAlert(true);
        setAlertMessage('No Email will be sent, That service is temporally unavailable');
        setSeverity('success');
      }
    }
  };
  if (!isAuth && registrationStatus === false && !registerFlag) {
    setRegisterFlag(true);
    setLoading(false);
    updateLoginStatus(null);
    setAlertRegistrationSeverity('error');
    setAlertRegistrationMessage(registrationMessage);
    setOpenRegistrationAlert(true);
  }
  if (!isAuth && registrationStatus === true && registerSuccess) {
    setRegisterSuccess(false);
    setLoading(false);
    updateLoginStatus(null);
    setAlertRegistrationSeverity('success');
    setAlertRegistrationMessage('Success!! Account is created. Please login with the credentials created.');
    setOpenRegistrationAlert(true);
    setTimeout(() => {
      setOpenRegistrationAlert(false);
      setOpenRegistration(false);
      setRegistrationForm({
        ...registrationForm,
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        company_name: '',
        showPassword: false,
      });
    }, 5000);
  }

  const handleChange = (prop) => (event) => {
    setRegistrationForm({ ...registrationForm, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setRegistrationForm({ ...registrationForm, showPassword: !registrationForm.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const closeRegistration = () => {
    setRegistrationForm({
      ...registrationForm,
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      password: '',
      company_name: '',
      showPassword: false,
    });
    setOpenRegistration(false);
  };

  const forgotPass = () => {
    if (email.trim() !== '') {
      const pattern = new RegExp(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, //eslint-disable-line
      );
      if (!pattern.test(email)) {
        alert('Please enter valid email address!!');
      } else {
        forgotPassword({ email, url: window.location.origin });
        setOpenAlert(true);
        setAlertMessage(/* Please check your email for password reset link */'This service is temporally unavailable');
        setSeverity('success');
      }
    } else {
      alert('Please enter your email');
    }
  };

/*  fetchBranding().then((result) => {
    console.log('Branding = %o', result);
  });*/

  return (
      <div className={classes.root}>
        {openAlert ? (
            <Alert
                variant="filled"
                onClose={handleAlertClose}
                severity={alertSeverity}
                className={alertSeverity === 'error' ? classes.errorAlert : ''}
            >
              {alertMesage}
            </Alert>
        ) : null}
        <Dialog open={openRegistration} fullWidth onClose={handleClose}>
          <div>
            <IconButton onClick={handleClose} style={{ float: 'right' }}>
              <CloseIcon
                  style={{
                    transform: 'scale(4)',
                    backgroundColor: '#E9E9E9',
                    color: '#B1B1B1',
                    margin: '7px 5px 0px 0px',
                    width: '4px',
                    height: '4px',
                    padding: 1,
                    borderRadius: '1px',
                  }}
              />
            </IconButton>
          </div>
          <DialogTitle style={{ textAlign: 'center' }}><strong>Create Account</strong></DialogTitle>
          <DialogContent style={{ padding: '0px 56px' }}>
            {openRegistrationAlert ? (
                <Alert
                    variant="filled"
                    onClose={handleAlertClose}
                    severity={alertRegistrationSeverity}
                    className={classes.registerAlert}
                >
                  {alertRegistrationMesage}
                </Alert>
            ) : null}
            <form className={classes.formControl} onSubmit={createAccount}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <InputLabel className={classes.label}>
                    First Name
                  </InputLabel>
                  <TextField
                      className={classes.inputFieldPadding}
                      required
                      type="text"
                      placeholder="First Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                        },
                        inputMode: 'string',
                      }}
                      variant="filled"
                      size="small"
                      value={registrationForm.first_name}
                      onChange={handleChange('first_name')}
                  />
                </div>
                <div>
                  <InputLabel className={classes.label}>
                    Last Name
                  </InputLabel>
                  <TextField
                      className={classes.inputFieldPadding}
                      required
                      type="text"
                      placeholder="Last Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                        },
                        inputMode: 'string',
                      }}
                      variant="filled"
                      size="small"
                      value={registrationForm.last_name}
                      onChange={handleChange('last_name')}
                  />
                </div>
              </div>
              <div>
                <InputLabel className={classes.label}>
                  Company Name
                </InputLabel>
                <TextField
                    className={classes.inputFieldPadding}
                    required
                    type="text"
                    placeholder="Company Name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                      inputMode: 'string',
                    }}
                    variant="filled"
                    size="small"
                    fullWidth
                    value={registrationForm.company_name}
                    onChange={handleChange('company_name')}
                />
              </div>
              <div className={classes.loader}>
                {loading ? (
                    <CircularProgress color="primary" className={classes.loaderIcon} />
                ) : null}
              </div>
              <div>
                <InputLabel className={classes.label}>
                  Phone number
                </InputLabel>
                <NumberFormat
                    placeholder="Phone number"
                    onChange={handleChange('phone_number')}
                    customInput={TextField}
                    value={registrationForm.phone_number}
                    required
                    className={classes.numFormat}
                    format="+1 (###) ###-####"
                    mask="_"
                />
              </div>
              <div>
                <InputLabel className={classes.label}>
                  Email Address
                </InputLabel>
                <TextField
                    className={classes.inputFieldPadding}
                    required
                    type="email"
                    placeholder="Email address"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                      inputMode: 'string',
                    }}
                    variant="filled"
                    size="small"
                    fullWidth
                    value={registrationForm.email}
                    onChange={handleChange('email')}
                />
              </div>
              <div>
                <InputLabel className={classes.label}>Password</InputLabel>
                <TextField
                    className={classes.passwordField}
                    required
                    id="input-with-icon-textfield"
                    type={registrationForm.showPassword ? 'text' : 'password'}
                    value={registrationForm.password}
                    onChange={handleChange('password')}
                    placeholder="Password"
                    variant="filled"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                              {registrationForm.showPassword
                                  ? <Visibility className={classes.passwordIcon} />
                                  : <VisibilityOff className={classes.passwordIcon} />}
                            </IconButton>
                          </InputAdornment>
                      ),
                    }}
                />
              </div>
              <Box mt={3} style={{ textAlign: 'center', marginBottom: '12px' }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="small"
                    classes={{ root: classes.button }}
                >
                  CREATE ACCOUNT
                </Button>
              </Box>
              <Box align="center" mb={5}>
                <Typography variant="caption">Already have an account?</Typography>
                <Typography variant="subtitle1">
                  <Button color="primary" onClick={closeRegistration}>
                    <Typography
                        className={classes.boldFont}
                        variant="caption"
                    >
                      SIGN IN
                    </Typography>
                  </Button>
                </Typography>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
          <Grid item xs={6}>
            <Box component="div" ml={5}>
              <img alt="logo" src={iLearnLogo} width="85vw" />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
                container="true"
                component="div"
                borderRadius="borderRadius"
                className={classes.navContainer}
                mr={5}
                fontWeight="fontWeightLight"
            >
              {/* <Button className={classes.linkFont} size="small">
              CONTACT US
            </Button>
            <span>|</span>
            <Button className={classes.linkFont} size="small">
              ABOUT US
            </Button>
            <span>|</span>
            <Button className={classes.linkFont} size="small">
              HELP
            </Button> */}
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Box mt={5}>
              <Grid className={classes.ligthBg} container>
                <Grid item xs={7}>
                  <Box component="div" mt={8}>
                    <img alt="logo" src={loginBg} width="90%" />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box component="div">
                    <Paper
                        className={`${classes.root} ${classes.loginCardContainer}`}
                        elevation={0}
                    >
                      <Typography
                          variant="subtitle1"
                          align="center"
                          className={classes.boldFont}
                      >
                        Please Sign in!
                      </Typography>
                      <form className={classes.formControl} onSubmit={submitForm}>
                        <InputLabel className={classes.label}>
                          Email Id
                        </InputLabel>
                        <TextField
                            id="email-input"
                            className={classes.loginInputFieldPadding}
                            style={{ width: '100% !important' }}
                            required
                            type="email"
                            placeholder="Enter registered email ID"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                              inputMode: 'string',
                            }}
                            variant="filled"
                            size="small"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <InputLabel className={classes.label}>
                          Password
                        </InputLabel>
                        <TextField
                            className={classes.loginInputFieldPadding}
                            id="password-input"
                            required
                            type="password"
                            placeholder="Enter password"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                              },
                              inputMode: 'string',
                            }}
                            variant="filled"
                            size="small"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}

                        />
                        <Box mt={5}>
                          <Button
                              type="submit"
                              variant="contained"
                              fullWidth
                              color="primary"
                              disableElevation
                          >
                            SIGN IN
                          </Button>
                        </Box>
                        <Box className={classes.checkBoxContainer}>
                          {/* <FormControlLabel
                          className={classes.smallFont}
                          control={(
                            <Checkbox
                              className={classes.smallFont}
                              checked={checked}
                              onChange={handleCheckBox}
                              name="remember"
                              id="rememberMe"
                              color="primary"
                            />
                          )}
                          label="REMEMBER ME"
                        /> */}
                          <div color="primary" className={classes.btnDiv}>
                            <Typography variant="caption" style={{ whiteSpace: 'noWrap' }}>
                              <Button
                                  className={classes.boldFont}
                                  onClick={forgotPass}
                              >
                                FORGOT PASSWORD
                              </Button>
                            </Typography>
                          </div>
                        </Box>
                        <Box my={3}>
                          <Typography
                              // className={classes.borderLine}
                              type="title"
                              color="inherit"
                          />
                        </Box>
                      </form>
                      {allowNewCompany && (
                          <Box align="center">
                            <Typography variant="caption">No account yet?</Typography>
                            <Typography variant="subtitle1">
                              <Button color="primary" onClick={() => setOpenRegistration(true)}>
                                <Typography
                                    className={classes.boldFont}
                                    variant="caption"
                                >
                                  CREATE NOW
                                </Typography>
                              </Button>
                            </Typography>
                          </Box>)}
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
  );
};

Login.propTypes = {
  isAuth: PropTypes.bool,
  loginUser: PropTypes.func,
  fetchPermissionDetails: PropTypes.func,
  errMessage: PropTypes.string,
  loginStatus: PropTypes.any,
  updateLoginStatus: PropTypes.func,
  registerUser: PropTypes.func,
  registrationMessage: PropTypes.string,
  registrationStatus: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isAuth: state.login.isAuth,
  errMessage: state.login.errMessage,
  loginStatus: state.login.loginStatus,
  registrationMessage: state.login.registrationMessage,
  registrationStatus: state.login.registrationStatus,
});

const mapDispatchToProps = {
  loginUser,
  fetchPermissionDetails,
  updateLoginStatus,
  registerUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
