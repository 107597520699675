import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, LinearProgress,
    Tooltip
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import React, {useEffect, useState} from "react";
import FileSaver from "file-saver";
import {deleteLevel, deleteScript, downloadExcelLevel, generateAudio} from "../../../services/result.service";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";

const LevelActions = ({
    classes,
    updateData,
    data,
    levelIndex,
    //deleted,
    setDeleted,
    setData,
    setIndex,
    setLoad,
    load
}) => {
    const [level, setLevel] = useState(null);
    const [deleteLastLevel, setDeleteLastLevel] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [audioOpen, setAudioOpen] = useState(false);
    const [audioCount, setAudioCount] = React.useState(0);

    const handleLevelAudioGenerate = React.useCallback(
        async () => {
            setLoad && setLoad(true);
            const sim = { ...data };
            for (let i = 0; i < sim.levels[levelIndex].scripts.length; i++) {
                const character = data.sim_characters.find(
                    (item) =>
                        item._id === sim.levels[levelIndex].scripts[i].sim_character_id
                );
                if (
                    character.type === "agent" &&
                    !sim.levels[levelIndex].scripts[i].agent_recorded_audio
                ) {
                    if (sim.levels[levelIndex].order === 0 || sim.levels[levelIndex].order === 1) {
                        const req = {};
                        req.sim_script_id = sim.levels[levelIndex].scripts[i]._id;
                        req.sim_id = sim.levels[levelIndex].scripts[i].sim_id;
                        req.voice = character.voice;
                        const response = await generateAudio(req);
                        sim.levels[levelIndex].scripts[i].audio_url = response.audio_url;
                        sim.levels[levelIndex].scripts[i].expiry_date = response.expiry_date;
                        sim.levels[levelIndex].scripts[i].audio_key = response.audio_key;
                        sim.levels[levelIndex].scripts[i].agent_recorded_audio = true;
                        sim.levels[levelIndex].scripts[i].voice = character.voice;
                        setData(sim);
                        await updateData(i, sim.levels[levelIndex].scripts[i]);
                    }
                    // If the character type is 'agent' and enabled to prompt to record, then do not generate audio for that script
                } else {
                    const req = {};
                    req.sim_script_id = sim.levels[levelIndex].scripts[i]._id;
                    req.sim_id = sim.levels[levelIndex].scripts[i].sim_id;
                    req.voice = character.voice;
                    const response = await generateAudio(req);
                    sim.levels[levelIndex].scripts[i].audio_url = response.audio_url;
                    sim.levels[levelIndex].scripts[i].expiry_date = response.expiry_date;
                    sim.levels[levelIndex].scripts[i].audio_key = response.audio_key;
                    sim.levels[levelIndex].scripts[i].voice = character.voice;
                    if (sim.levels[levelIndex].order === 0 || sim.levels[levelIndex].order === 1) {
                        sim.levels[levelIndex].scripts[i].agent_recorded_audio = true
                    }
                    setData(sim);
                    await updateData(i, sim.levels[levelIndex].scripts[i]);
                }
            }
            setLoad && setLoad(false);
            setAudioOpen(false);
        }
    );

    const getAudioCount = React.useCallback(
        () => {
            if (!data || !data.levels || !data.levels[levelIndex] || !data.levels[levelIndex].scripts) {
                return;
            }

            const result = data?.levels[levelIndex]?.scripts?.filter(
                (script) => script?.audio_url !== ""
            );
            if (result.length <= 0) {
                //handleLevelAudioGenerate();
            } else if (result.length !== audioCount) {
                setAudioCount(result.length);
            }
        }, [data, levelIndex, setAudioCount, setAudioOpen, handleLevelAudioGenerate]
    );

    useEffect(() => {
        setLevel(data?.levels[levelIndex] ?? null);
    }, [data, levelIndex, level, setLevel]);

    const downloadLevel = async () => {
        if (!level) {
            return;
        }

        downloadExcelLevel(level._id).then((response) => {
            const fileName = level.name.replace(/\//g, '_');
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, `${fileName}.xlsx`);
        });
    };

    const handleDeleteLevel = async () => {
        const sim = { ...data };
        if (sim.levels.length === 1) {
            setDeleteLastLevel(true);
            setDeleteOpen(true);
        } else {
            setDeleteOpen(true);
        }
    };

    const deleteConfirm = async () => {
        setDeleted(true);
        const sim = { ...data };

        if(sim.is_pass_fail_criteria_set) {
            sim.is_pass_fail_criteria_set = false;
            sim.pass_fail_id = {};
        }

        if (sim.levels[levelIndex]._id === undefined) {
            sim.levels.splice(levelIndex, 1);
        } else if (deleteLastLevel) {
            const data = {}
            data.sim_level_id = sim.levels[levelIndex]._id;
            await deleteScript(data);
            setDeleteLastLevel(false);
            sim.levels[levelIndex].scripts.splice(0, sim.levels[levelIndex].scripts.length);
            setData(sim);
        } else {
            const requestData = {};
            requestData.level_id = sim.levels[levelIndex]._id;
            requestData.sim_id = sim.levels[levelIndex].sim_id;
            await deleteLevel(requestData);
            sim.levels.splice(levelIndex, 1);
        }

        if (levelIndex > 0) {
            setIndex(levelIndex - 1);
        }

        setData(sim);
        setDeleteOpen(false);
    };

    const deleteCancel = async() => {
        setDeleteLastLevel(false);
        setDeleteOpen(false);
    }

    const handleGenerateAudioClick = (evt) => {
        if (data?.levels[levelIndex] && data?.levels[levelIndex]?.scripts?.length) {
            setAudioOpen(true);
            getAudioCount();
            setLoad(false);
        }
    }

    return (
        <>
            <div>
                <Box className={classes.iconBox} style={{verticalAlign: "middle"}}>
                    <Tooltip title={'Generate Audio for Level '+level?.name}>
                        <Button className={classes.closeButton} style={{padding: '0.25em', width: 'fit-content', minWidth: 'unset'}}>
                            <VolumeUpRoundedIcon
                                className={classes.iconDownload}
                                onClick={handleGenerateAudioClick}
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip title={'Download level '+level?.name+' as a CSV'}>
                        <Button className={classes.closeButton} style={{padding: '0.25em', width: 'fit-content', minWidth: 'unset'}}>
                            <GetAppIcon
                                className={classes.iconDownload}
                                onClick={downloadLevel}
                            />
                        </Button>
                    </Tooltip>
                    <span className={classes.verticalDivider}/>
                    <Tooltip title={"Delete Level "+level?.name}>
                        <Button className={classes.closeButton} style={{padding: '0.25em', width: 'fit-content', minWidth: 'unset'}}>
                            <DeleteOutlineIcon
                                className={classes.iconDelete}
                                onClick={handleDeleteLevel}
                            />
                        </Button>
                    </Tooltip>
                </Box>
            </div>

            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Level</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Do you want to delete the level "${level?.name ?? ''}"`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => deleteCancel()}
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => deleteConfirm()}
                        className={classes.dialogDeleteBtn}
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={audioOpen}
                onClose={() => setAudioOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Generate Audio
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`There are ${audioCount} audio clips already. Are you sure you want to record over them?`}
                    </DialogContentText>
                    <div className={classes.progress}>
                        {load ? <LinearProgress color="primary" /> : null}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAudioOpen(false)} className={classes.cancelButton}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleLevelAudioGenerate()}
                        className={classes.dialogDeleteBtn}
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default LevelActions;