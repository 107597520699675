/* eslint-disable react/no-array-index-key */
/* eslint-disable no-continue */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    Grid,
    Toolbar,
    Paper,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    LinearProgress,
    CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import MicOutlinedIcon from '@material-ui/icons/MicOutlined';
import { useLocation } from "react-router-dom";
import {
    increaseStep,
    fetchCurrentSim,
    decreaseStep,
    fetchCharacterList,
    addLevels,
    addNewLevels,
    uploadLevels,
    fetchAutoPopulate,
    fetchSimDetails,
} from "../../../redux/sims/actions";
import Characters from "./Characters.jsx";
import CharacterData from "./CharacterData.jsx";
import TopTableToolbar from "./TopTableToolbar.jsx";
import GenerateSim from './GenerateSim.jsx';
import Levels from './Levels.jsx';
import Details from "../Details.jsx";
import {
    addLevelScript,
    editLevels,
    deleteScript,
    duplicateSim, generateAudio
} from "../../../services/result.service";
import { useStyle } from "./CreateLevels";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import DropZone from '../dragDrop/DropZone.jsx';
import '../dragDrop/style.css';
import { handleMoveWithinParent, handleMoveSidebarComponentIntoParent } from "../dragDrop/helpers";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LevelOptions from "./LevelOptions";

const useQuery = () => new URLSearchParams(useLocation().search);

const getBackground = (charachter) => {
    let background = "";
    switch (charachter) {
        case "coach":
            background = "#882647";
            break;
        case "agent":
            background = "#EB6D20";
            break;
        case "customer":
            background = "#2694D3";
            break;
        case "other":
            background = "#2CB174";
            break;
        default:
            background = "#882647";
    }
    return background;
};

const CreateLevels = ({
    newSim,
    fetchCurrentSim,
    currentSim,
    decreaseStep,
    increaseStep,
    addLevels,
    uploadLevels,
    fetchAutoPopulate,
    tabs,
    fetchSimDetails,
    status,
}) => {
    const classes = useStyle();
    const query = useQuery();
    const [isLoading, setIsLoading] = useState(true);
    const [simGenerated, setSimGenrated] = useState(false);
    const [data, setData] = useState({ ...newSim });
    const [open, setOpen] = React.useState(false);
    const [levelSelect, setLevelSelect] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [openSuccessAlert, setSuccessAlert] = React.useState(false);
    const [currentIndex, setIndex] = React.useState(0);
    const [alertMesage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("error");
    const [load, setLoad] = React.useState(false);
    const [flag, setFlag] = useState(true);
    const [totalLevel, setTotalLevel] = useState(0);
    const [deleted, setdeleted] = React.useState(false);
    const [selectedClip, setSelectedClip] = React.useState('');
    const [importLevel, setImportLevel] = React.useState(false);
    const [levelName, setLevelName] = React.useState(
        data.levels[currentIndex] ? data.levels[currentIndex].name : ""
    );
    const [timePlay, setTimePlay] = useState(0);
    const [currentAudio, setCurrentAudio] = useState({});
    const clipRef = useRef(null);

    const handleClickOpen = () => {
        setLevelName(data.levels[currentIndex].name);
        setOpen(true);
    };

    const handleClose = () => {
        setLevelName('');
        setOpen(false);
    };
    const initialize = () => {
        if (query.get("simId") && !data.id) {
            fetchSimDetails(query.get("simId"));
        } else {
            setIsLoading(false);
        }
    };
    useEffect(initialize, []);

    useEffect(() => {
        if (query.get("simId") && data.fetchStatus === true && data.levels.length) {
            setIsLoading(false);
            localStorage.setItem('sims_id', query.get("simId"))
        }
    }, [data, query]);

    useEffect(() => {
        if (data.id && currentSim && Object.keys(currentSim).length === 0) {
            fetchCurrentSim(data.id);
        }
    }, [fetchCurrentSim, data.id, currentSim]);

    useEffect(() => {
        fetchAutoPopulate(query.get("simId")); // eslint-disable-next-line
    }, [fetchAutoPopulate]);

    useEffect(() => {
        if (selectedClip) {
            clipRef.current.scrollIntoView(false);
        }
    }, [clipRef, selectedClip]);

    const initializeLevelArray = React.useCallback(() => {
        const simData = { ...data };
        const simLevels = ["Watch and Learn"];
        if (simData.levels.length === 0) {
            let index = 1;
            for (const level of simLevels) {
                simData.levels.push({
                    _id: undefined,
                    name: level,
                    order: index - 1,
                    real_time_feedback: false,
                    natural_lenguage: data.natural_lenguage,
                    send_to_snowfly: false,
                    hint_text: false,
                    keyword_score: true,
                    scripts: [],
                });
                index += 1;
            }
        }
        setData(simData);
    }, [data]);

    useEffect(() => {
        if (data.id && data.levels.length <= 0) {
            initializeLevelArray();
        }
    }, [data.id, data.levels, initializeLevelArray]);

    useEffect(() => {
        if (data && data.levels.length) {
            setTotalLevel(data.levels.length);
            setLevelSelect(false);
            if (data.levels[currentIndex].scripts.length) {
                data.levels[currentIndex].scripts.map((script) => {
                    script.play = true;
                    return script;
                });
            }
        }
    }, [data, currentIndex]);
    useEffect(() => {
        setData({ ...newSim });
    }, [newSim]);

    const updateData = async (index, characterData) => {
        const simData = { ...data };
        const requestData = {};
        const addIntialScript = async (requestData) => {
            const response = await addLevelScript(requestData);
            simData.levels[currentIndex].scripts[index]._id = response.sim_script_id;
        };
        simData.levels[currentIndex].scripts[index].script = characterData.script;
        simData.levels[currentIndex].scripts[index].keywords = characterData.keywords;
        simData.levels[currentIndex].scripts[index].hint_text = characterData.hint_text;
        simData.levels[currentIndex].scripts[index].hide_text = characterData.hide_text;
        simData.levels[currentIndex].scripts[index].real_time_feedback = characterData.real_time_feedback;
        simData.levels[currentIndex].scripts[index].natural_lenguage = data.natural_lenguage;
        simData.levels[currentIndex].scripts[index].send_to_snowfly = characterData.send_to_snowfly;
        simData.levels[currentIndex].scripts[index].tests = characterData.tests;
        requestData.is_level = false;
        requestData._id = simData.levels[currentIndex].scripts[index]._id;
        requestData.sim_level_id = simData.levels[currentIndex]._id;
        requestData.sim_id = simData.id;
        requestData.script = characterData.script;
        requestData.sim_character_id = characterData.sim_character_id;
        requestData.keywords = characterData.keywords;
        requestData.tests = characterData.tests;
        requestData.hint_text = characterData.hint_text;
        requestData.hide_text = characterData.hide_text;
        requestData.autopopulate_timer = characterData.autopopulate_timer;
        requestData.autopopulate_data = characterData.autopopulate_data;
        requestData.audio_url = characterData.audio_url;
        requestData.order = simData.levels[currentIndex].scripts[index].order;
        requestData.real_time_feedback = characterData.real_time_feedback;
        requestData.natural_lenguage = data.natural_lenguage;
        requestData.agent_recorded_audio = characterData.agent_recorded_audio;
        requestData.overalConfidence = characterData.overalConfidence;
        addIntialScript(requestData);
        setData(simData);
    };

    const Level = (cLevel) => {
        const simData = { ...data };
        const index = simData.levels.findIndex(
            (level) => level.order === cLevel.order
        );
        setIndex(index);
        setTimePlay(0);
    };

    const handleChange = (value, index, name) => {
        const sim = { ...data };
        if (name === "script") {
            sim.levels[currentIndex].scripts[index].script = value;
        } else if (name === "hint_text") {
            sim.levels[currentIndex].scripts[index].hint_text = value;
        } else if (name === "keywords") {
            sim.levels[currentIndex].scripts[index].keywords = value;
        } else if (name === 'captivate_fileName') {
            sim.levels[currentIndex].scripts[index].captivate_folder_name = value;
        } else if (name === 'overalConfidence') {
            sim.levels[currentIndex].scripts[index].overalConfidence = value;
        }
        setData(sim);
    };

    const handleKeywordChange = (index, event) => {
        const sim = { ...data };
        sim.levels[currentIndex].scripts[index].keywords = [...event];
        setData(sim);
    };

    const handleTestChange = (index, updatedTests) => {
        const sim = { ...data };
        sim.levels[currentIndex].scripts[index].tests = updatedTests;
        setData(sim);
    };

    const handleAudioGenerate = async (index, url, expiry_date, key, type, voice) => {
        const sim = { ...data };
        sim.levels[currentIndex].scripts[index].agent_recorded_audio = type;
        sim.levels[currentIndex].scripts[index].audio_url = url;
        sim.levels[currentIndex].scripts[index].expiry_date = expiry_date;
        sim.levels[currentIndex].scripts[index].audio_key = key;
        sim.levels[currentIndex].scripts[index].voice = voice;
        setData(sim);
    };

    const handleEditLevel = (event) => {
        const { value } = event.target;
        setLevelName(value);
    };

    const handleSave = async () => {
        const sim = { ...data };
        data.levels[currentIndex].name = levelName;
        setData(sim);
        setOpen(false);
        editLevels(data.levels[currentIndex]._id, {
            name: data.levels[currentIndex].name,
            sim_id: data.levels[currentIndex].sim_id,
        });
        setLevelName("");
    };

    const handleCheckboxChange = (index, event) => {
        const sim = { ...data };
        sim.levels[currentIndex].scripts[index].hide_text = event.target.checked;
        setData(sim);
    };

    const handleRadioChange = (index, event) => {
        const sim = { ...data };
        sim.levels[currentIndex].scripts[index].real_time_feedback =
            event.target.value === "yes";
        setData(sim);
    };

    const handleDeleteScript = async (index, script) => {
        const sim = { ...data };
        const requestData = {};
        requestData.script_id = script._id;
        requestData.sim_id = script.sim_id;
        await deleteScript(requestData);
        sim.levels[currentIndex].scripts.splice(index, 1);
        setData(sim);
    };

    const handleDuplicateSim = async () => {
        const sim = { ...data };
        const requestData = {};
        requestData.simId = sim.id;
        const response = await duplicateSim(requestData);
        if (response.data.success) {
            setSuccessAlert(true);
        } else {
            setAlertType("error");
            setAlertMessage("Error in creating duplicate sim. Please try again.");
            setOpenAlert(false);
        }
    };

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlert(false);
    };

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessAlert(false);
        setAlertType("");
        setAlertMessage("");
    };

    const handleAudioChange = (index) => {
        const sim = { ...data };
        sim.levels[currentIndex].scripts[index].agent_recorded_audio = false;
        sim.levels[currentIndex].scripts[index].audio_url = "";
        setData(sim);
    };
    const handleAgentRecordedAudio = (index) => {
        const sim = { ...data };
        sim.levels[currentIndex].scripts[index].agent_recorded_audio = true;
        setData(sim);
    };

    const handleAutopopulateChange = async (index, array, timer) => {
        const sim = { ...data };
        sim.levels[currentIndex].scripts[index].autopopulate_timer = timer;
        sim.levels[currentIndex].scripts[index].autopopulate_data = array.autopopulate_data;
        sim.levels[currentIndex].scripts[index].autopopulate_data.forEach((tab) => {
                delete tab._id;
                tab.sections.forEach((field) => {
                    delete field._id;
                    field.fields.forEach((element) => {
                        delete element._id;
                    });
                })
            }
        );
        setData(sim);
    };

    const handleResetAutopopulate = async (index) => {
        const sim = { ...data };
        sim.levels[currentIndex].scripts[index].autopopulate_timer = 0;
        sim.levels[currentIndex].scripts[index].autopopulate_data = tabs;
        setData(sim);
    };

    const clipScroll = (id) => {
        setSelectedClip(id);
    }

    // Method to handle drag and drop in scripts
    const handleOnDragEnd = (result) => {
        const levelScript = { ...data };
        const items = Array.from(levelScript.levels[currentIndex].scripts);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        items.map(async (item, index) => {
            item.order = index + 1;
            if (item.newScript) {
                item.newScript = false;
            }
            await addLevelScript(item);
        });
        levelScript.levels[currentIndex].scripts = items;
        setData(levelScript);
    };

    const setTimePlayfun = (audioPlayer, dataa) => {
        data.levels[currentIndex].scripts.map((script) => {
            if (script._id === dataa._id) {
                script.play = false;
            } else {
                script.play = true;
            }
            return data;
        });
        setData(data)
        setCurrentAudio(audioPlayer);
        setTimePlay((prev) => prev + 1)
    }

    const pauseFunction = (dataa) => {
        data.levels[currentIndex].scripts.map((script) => {
            if (script._id === dataa._id) {
                script.play = true;
                setTimePlay(0);
            }
            return data;
        });
        setData(data);
    }

    const handleDrop = async(dropZone, item) => {
        let splitDropZonePath;
        let pathToDropZone;
        if (dropZone.path) {
            splitDropZonePath = dropZone.path.split("-");
            pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
        }
        const levelScript = { ...data };
        // sidebar info
        if (( item === undefined && dropZone.type) || item.character) {
            // 1. Move sidebar item into page
            const scriptData = levelScript.levels[currentIndex];
            const { length } = scriptData.scripts
            if (length === 0 || levelScript.levels[currentIndex]._id) {
                // push the script when double click the character
                if (dropZone.type) {
                    levelScript.levels[currentIndex].scripts.push({
                        order: length,
                        sim_character_id: dropZone._id,
                        script: "",
                        keywords: [],
                        hint_text: "",
                        hide_text: false,
                        audio_url: "",
                        autopopulate_timer: 0,
                        autopopulate_data: [...tabs],
                        status: "saved",
                        real_time_feedback: false,
                        natural_lenguage: data.natural_lenguage,
                        agent_recorded_audio: false,
                        newScript: true,
                    });
                }
                const requestData = {};
                if (
                    (scriptData.scripts.length === 0 || (dropZone.type && scriptData.scripts.length === 1)) &&
                    !levelScript.levels[currentIndex]._id
                ) {
                    // request data when initial script is added to rescpective level
                    levelScript.levels[currentIndex].scripts = dropZone.type ? levelScript.levels[currentIndex].scripts : [];
                    requestData.is_level = true;
                    requestData.name = levelScript.levels[currentIndex].name;
                    requestData.level_order = levelScript.levels[currentIndex].order;
                    requestData.real_time_feedback = levelScript.levels[currentIndex].real_time_feedback;
                    requestData.natural_lenguage = data.natural_lenguage;
                    requestData.level_hint_text = (levelScript.levels[currentIndex].order === 0 ? false : true);
                } else {
                    // request data when a respective level has more than one script
                    requestData.is_level = false;
                    requestData.sim_level_id = levelScript.levels[currentIndex]._id;
                    requestData.newScript = true;
                }
                requestData.sim_id = levelScript.id;
                requestData.script = "";
                requestData.sim_character_id = dropZone.type ? dropZone._id : item.character._id;
                requestData.audio_url = "";
                requestData.order = dropZone.type ? scriptData.scripts.length.toString() : splitDropZonePath[1];
                requestData.status = "saved";
                requestData.real_time_feedback = false;
                requestData.natural_lenguage = data.natural_lenguage;
                requestData.keywords = [];
                requestData.hint_text = "";
                requestData.hide_text = false;
                requestData.agent_recorded_audio = false;
                requestData.autopopulate_timer = 0;
                requestData.autopopulate_data = tabs;
                const response = await addLevelScript(requestData);
                if (response.sim_level_id) {
                    levelScript.levels[currentIndex]._id = response.sim_level_id;
                }
                if (!dropZone.type) {
                    let items = Array.from(levelScript.levels[currentIndex].scripts);
                    items = handleMoveSidebarComponentIntoParent(data.levels[currentIndex], splitDropZonePath, requestData).scripts;
                    items[splitDropZonePath[1]]._id = response.sim_script_id;
                    items.map(async (item, index) => {
                        if (items[splitDropZonePath[1]]._id !== item._id) {
                            item.newScript = false;
                        }
                        item.order = index + 1;
                        await addLevelScript(item);
                    });
                    levelScript.levels[currentIndex].scripts = items;
                } else {
                    levelScript.levels[currentIndex].scripts[length]._id = response.sim_script_id;
                    setSelectedClip(response.sim_script_id);
                }
                setData(levelScript);
            }
        } else {
            // move down here since sidebar items dont have path
            const splitItemPath = item.path.split("-");
            const pathToItem = splitItemPath.slice(0, -1).join("-");

            // 2. Pure move (no create)
            if (splitItemPath.length === splitDropZonePath.length) {
                // 2.a. move within parent
                if (pathToItem === pathToDropZone) {
                    let items = Array.from(levelScript.levels[currentIndex].scripts);
                    items = handleMoveWithinParent(data.levels[currentIndex], splitDropZonePath, splitItemPath).scripts;

                    items.map(async (item, index) => {
                        item.order = index + 1;
                        await addLevelScript(item);
                    });
                    levelScript.levels[currentIndex].scripts = items;
                    setData(levelScript);
                }
            }
        }
    }

    const handleLevelOptionChange = (evt) => {
        console.log('handleLevelOptionChange event = %o', evt);
        if (evt.detail.success) {
            let sim = {...data};
            sim.levels[evt.detail.levelIndex][evt.detail.option] = evt.detail.value;
            setData(sim);

            setLoad(false);
            setAlertType("success");
            setAlertMessage("Successfuly updated "+evt.detail.option+" feature");
            setOpenAlert(true);
        } else {
            setLoad(false);
            setAlertType("error");
            setAlertMessage("Error in changing "+evt.detail.option+" feature");
            setOpenAlert(true);
        }
    }

    /*
    const generateAudioForAll = async (ev) => {
        const level = data?.levels[currentIndex];
        if (!level) {
            return;
        }

        for await(let [i, clip] of level.scripts.entries()) {
            debugger;
            if (clip.voice) {
                const req = {
                    voice: clip.voice,
                    sim_script_id: clip._id,
                    sim_id: data._id,
                };
                let response = await generateAudio(req);
                await handleAudioGenerate(
                    i,
                    response.audio_url,
                    response.expiry_date,
                    response.audio_key,
                    false,
                    clip.voice,
                );
            }
            console.log('Clip = %o', clip);
        }
    }
    */

    const rowPath = 0;
    return (
        <Paper elevation={0}>
            <TopTableToolbar
                updateData={updateData}
                decreaseStep={decreaseStep}
                increaseStep={increaseStep}
                data={data}
                addLevels={addLevels}
                uploadLevels={uploadLevels}
                newSim={newSim}
                setFlag={setFlag}
                status={status}
                importLevel={() => setImportLevel((prev) => !prev)}
                handleDuplicateSim={handleDuplicateSim}
            />
            <Details currentSim={currentSim} title={newSim.title} />
            {openAlert ? (
                <Alert
                    variant="filled"
                    onClose={handleAlertClose}
                    severity={alertType ? alertType : 'error'}
                    className={alertType === 'error' ? classes.errorAlert : ''}
                >
                    {alertMesage}
                </Alert>
            ) : null}
            {openSuccessAlert ? (
                <Alert
                    className={classes.alert}
                    variant="filled"
                    onClose={handleSuccessAlertClose}
                    severity="success"
                >
                    Success! Duplicate Sim Created.
                </Alert>
            ) : null}
            <div className={classes.mainDiv}>
                {isLoading ? (
                    <div className={classes.loaderDiv}>
                        <CircularProgress color="primary" />
                    </div>
                ) : null}
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={2} sm={2} lg={2} xl={2}>
                        <Levels
                            simGenerated={simGenerated}
                            Level={Level}
                            levels={data.levels}
                            passFailLevel={data?.is_pass_fail_criteria_set ? data?.pass_fail_id?.sim_level_id : null}
                            data={data}
                            setData={setData}
                            newSim={newSim}
                            updateData={updateData}
                            deleted={deleted}
                            flag={flag}
                            setFlag={setFlag}
                            setdeleted={setdeleted}
                            setIndex={setIndex}
                            onOptionChange={handleLevelOptionChange}
                            setAlertType={setAlertType}
                            setAlertMessage={setAlertMessage}
                            setOpenAlert={setOpenAlert}
                            setLoad={setLoad}
                            load={load}
                        />
                    </Grid>
                    <DndProvider backend={HTML5Backend}>
                        <Grid item className={classes.editCanvas} sm={8} lg={8} xl={8} xs={7}>
                            <Toolbar className={classes.toolBar}>
                                <Typography className={classes.canvasLabel}>
                                    {currentIndex + 1}.{" "}
                                    {data.levels[currentIndex]
                                        ? data.levels[currentIndex].name
                                        : ""}
                                    <Button
                                        disabled={
                                            !(
                                                data.levels &&
                                                data.levels.length > 0 &&
                                                data.levels[currentIndex]
                                            )
                                        }
                                        className={classes.editBtn}
                                        color="default"
                                        onClick={handleClickOpen}
                                        startIcon={<EditIcon className={classes.editIcon} />}
                                    />
                                </Typography>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="levelName"
                                    fullWidth
                                    maxWidth="sm"
                                >
                                    <DialogTitle id="LevelName">Edit Level Name</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            autoFocus
                                            id="LevelName"
                                            margin="dense"
                                            name="name"
                                            multiline
                                            value={levelName}
                                            onChange={(e) => handleEditLevel(e)}
                                            fullWidth
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} className={classes.cancelButton}>
                                            Cancel
                                        </Button>
                                        <Button onClick={handleSave} color="primary">
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <GenerateSim
                                    currentIndex={currentIndex}
                                    data={data}
                                    setTotalLevel={setTotalLevel}
                                    totalLevel={totalLevel}
                                    setSimGenrated={setSimGenrated}
                                    setLevelSelect={setLevelSelect}
                                    levelSelect={levelSelect}
                                    handleClose={handleClose}
                                    setLoad={setLoad}
                                    setFlag={setFlag}
                                    setAlertType={setAlertType}
                                    setAlertMessage={setAlertMessage}
                                    setOpenAlert={setOpenAlert}
                                />{/*
                                <Tooltip title={'Generate audio for all characters'}>
                                    <Button variant="outlined"
                                            onClick={generateAudioForAll}
                                    >
                                        Generate Audio
                                    </Button>
                                </Tooltip>*/}
                                <div>
                                    <LevelOptions
                                        data={data}
                                        setData={setData}
                                        levelIndex={currentIndex}
                                        singleLevel={data?.levels[currentIndex] ?? null}
                                        passFailLevel={data?.is_pass_fail_criteria_set ? data?.pass_fail_id?.sim_level_id : ''}
                                        classes={classes}
                                        onOptionChange={handleLevelOptionChange}
                                        setAlertType={setAlertType}
                                        setAlertMessage={setAlertMessage}
                                        setOpenAlert={setOpenAlert}
                                        updateData={updateData}
                                        setLoad={setLoad}
                                        load={load}
                                    />
                                </div>
                            </Toolbar>
                            <div className={classes.progress}>
                                {load ? <LinearProgress color="primary" /> : null}
                            </div>
                            <div>
                                {data?.levels[currentIndex]?.scripts.map((clip, i) => {
                                        let currentChar;
                                        const agentCharIndex = data?.sim_characters?.findIndex(char => char.type === 'agent')
                                        const customerCharIndex = data?.sim_characters?.findIndex(char => char.type === 'customer')
                                        const otherCharIndex = data?.sim_characters?.findIndex(char => char.type === 'other')
                                        if (data?.sim_characters[agentCharIndex]?._id === clip.sim_character_id) {
                                            currentChar = (<span key={clip._id}><Tooltip key={clip._id} title={clip.script}><MicOutlinedIcon className={classes.agentMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                                                {(i + 1) % 50 === 0 ? <br></br> : ''}
                    </span>)
                                            if (clip.agent_recorded_audio) {
                                                currentChar = (<span key={clip._id}><Tooltip key={clip._id} title={clip.script}><VolumeUpIcon className={classes.agentMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                                                    {(i + 1) % 50 === 0 ? <br></br> : ''}
                      </span>)
                                            }
                                        } else if (data?.sim_characters[customerCharIndex]?._id === clip.sim_character_id) {
                                            currentChar = (<span key={clip._id}><Tooltip title={clip.script}><VolumeUpIcon className={classes.customerMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                                                {(i + 1) % 50 === 0 ? <br></br> : ''}
                    </span>)
                                        } else if (data?.sim_characters[otherCharIndex]?._id === clip.sim_character_id) {
                                            currentChar = (<span key={clip._id} ><Tooltip title={clip.script}><VolumeUpIcon className={classes.otherMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                                                {(i + 1) % 50 === 0 ? <br></br> : ''}
                    </span>)
                                        } else {
                                            currentChar = (<span key={clip._id}><Tooltip title={clip.script}><VolumeUpIcon className={classes.coachMicIcon} onClick={() => clipScroll(clip._id)} /></Tooltip>
                                                {(i + 1) % 50 === 0 ? <br></br> : ''}
                    </span>)
                                        }
                                        return currentChar
                                    }
                                )}
                            </div>
                            <div
                                className={
                                    data.levels.length > 0 &&
                                    data.levels[currentIndex] &&
                                    data.levels[currentIndex].scripts.length === 0
                                        ? classes.scriptDiv1
                                        : classes.scriptDiv
                                }
                            >
                                {data.levels.length > 0 &&
                                data.levels[currentIndex] &&
                                data.levels[currentIndex].scripts.length === 0 ? (
                                    <section className={classes.backgroundImage} >
                                        <DropZone
                                            data={{
                                                path: `${rowPath}-${data.levels[currentIndex].scripts.length}`,
                                                childrenCount: data.levels[currentIndex].scripts.length
                                            }}
                                            onDrop={handleDrop}
                                            isLast
                                        />
                                    </section>
                                ) : null}
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="characters">
                                        {(provided) => (
                                            <div
                                                className="characters"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {data.levels.length > 0 &&
                                                    data.levels[currentIndex] &&
                                                    data.levels[currentIndex].scripts.map(
                                                        (script, index) => {
                                                            const currentPath = `${rowPath}-${index}`;
                                                            return (
                                                                <Draggable
                                                                    key={script._id}
                                                                    draggableId={script._id}
                                                                    index={index}
                                                                >
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <DropZone
                                                                                data={{
                                                                                    path: currentPath,
                                                                                    childrenCount: data.levels[currentIndex].scripts.length
                                                                                }}
                                                                                onDrop={handleDrop}
                                                                            />
                                                                            <div ref={selectedClip === script._id ? clipRef : null}>
                                                                                <CharacterData
                                                                                    path={currentPath}
                                                                                    onlyKeywords={data.levels[currentIndex].only_keywords}
                                                                                    key={index}
                                                                                    index={index}
                                                                                    data={script}
                                                                                    sim={data}
                                                                                    tabs={tabs}
                                                                                    setTimePlayfun={setTimePlayfun}
                                                                                    currentAudio={currentAudio}
                                                                                    timePlay={timePlay}
                                                                                    pauseFunction={pauseFunction}
                                                                                    characterData={data.sim_characters}
                                                                                    handleChange={handleChange}
                                                                                    handleAudioChange={handleAudioChange}
                                                                                    handleKeywordChange={handleKeywordChange}
                                                                                    handleTestChange={handleTestChange}
                                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                                    handleAudioGenerate={handleAudioGenerate}
                                                                                    handleRadioChange={handleRadioChange}
                                                                                    handleDeleteScript={handleDeleteScript}
                                                                                    handleAgentRecordedAudio={
                                                                                        handleAgentRecordedAudio
                                                                                    }
                                                                                    handleAutopopulateChange={
                                                                                        handleAutopopulateChange
                                                                                    }
                                                                                    handleResetAutopopulate={
                                                                                        handleResetAutopopulate
                                                                                    }
                                                                                    updateData={updateData}
                                                                                    borderColor={getBackground(script.type)}
                                                                                    levelOrder={data.levels[currentIndex].order}
                                                                                    newSim={newSim}
                                                                                    currentVoice={script.voice
                                                                                        ? [script.voice] : newSim.sim_characters.filter((char) =>
                                                                                            char._id === script.sim_character_id).map(char => char.voice)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        }
                                                    )}

                                                {provided.placeholder}

                                            </div>
                                        )}

                                    </Droppable>
                                </DragDropContext>
                                <div>
                                    {
                                        data.levels.length > 0 &&
                                        data.levels[currentIndex] &&
                                        data.levels[currentIndex].scripts &&
                                        <DropZone
                                            data={{
                                                path: `${rowPath}-${data.levels[currentIndex].scripts.length}`,
                                                childrenCount: data.levels[currentIndex].scripts.length
                                            }}
                                            onDrop={handleDrop}
                                            isLast
                                        />
                                    }
                                </div>
                                {
                                    importLevel && data.levels[currentIndex].scripts.length === 0 ?
                                        <div className={classes.loaderDiv}>
                                            <CircularProgress color="primary" />
                                        </div> : ''
                                }
                            </div>
                        </Grid>
                        <Grid
                            className={
                                data.levels &&
                                data.levels.length > 0 &&
                                data.levels[currentIndex] &&
                                data.levels[currentIndex].scripts.length === 0
                                    ? classes.intialCharacterMainGrid
                                    : classes.finalCharacterMainGrid
                            }
                            item
                            xs={2} lg={2} xl={2} sm={2}
                            key="card"
                        >
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Typography className={classes.characterLabel}>
                                    Characters
                                </Typography>
                                {data.sim_characters.map((character, index) => (
                                    <Characters
                                        bgColor={getBackground(character.type)}
                                        avatar={character.avatar}
                                        key={index}
                                        character={character}
                                        showDetails={() => handleDrop(character)}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </DndProvider>
                </Grid>
            </div >
        </Paper >
    );
};

CreateLevels.propTypes = {
    newSim: PropTypes.object,
    increaseStep: PropTypes.func,
    decreaseStep: PropTypes.func,
    currentSim: PropTypes.object,
    levels: PropTypes.array,
    fetchCurrentSim: PropTypes.func,
    addLevels: PropTypes.func,
    uploadLevels: PropTypes.func,
    fetchAutoPopulate: PropTypes.func,
    tabs: PropTypes.array,
    fetchSimDetails: PropTypes.func,
};

const mapStateToProps = (state) => ({
    status: state.sim.status,
    newSim: state.sim.newSim,
    currentSim: state.sim.currentSim,
    levels: state.sim.levels,
    characters: state.sim.characters,
    tabs: state.sim.autoPopulate,
});

const mapDispatchToProps = {
    increaseStep,
    decreaseStep,
    fetchCurrentSim,
    fetchCharacterList,
    addLevels,
    addNewLevels,
    uploadLevels,
    fetchAutoPopulate,
    fetchSimDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLevels);
